import React from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import { arrayOf, object, shape, string } from 'prop-types';

import { FAQ, Footer, Hero, SEO } from '../../components';

import './guide.css';

const GuideTemplate = ({ data }) => {
  const {
    heading,
    headerImage,
    faqGroup,
    brandDocumentationLink,
    brandDocumentationTitle,
    youtubeEmbedLink,
    guideContent,
    manufacturer,
    manufacturerWebsite,
    iosLink,
    androidLink,
    connectedAccountCost,
    path,
    seo,
    theme,
  } = data.graphCmsGuide;

  return (
    <>
      <SEO seo={seo} path={path} />
      <Hero
        heading={heading}
        image={headerImage}
        manufacturerInfo={{
          manufacturer,
          connectedAccountCost,
          manufacturerWebsite,
          iosLink,
          androidLink,
        }}
        theme={theme}
      />
      <main className="bg-grey-light h-full xl:pt-20 relative">
        <div className="container bg-white py-12 md:py-20 ">
          <div className="container bg-white pb-8 lg:px-36">
            <h2 className="pb-8">Setup guide</h2>
            {youtubeEmbedLink && (
              <iframe
                title="Application Setup Instructions"
                src={youtubeEmbedLink}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-64 md:h-96 aspect-video "
              />
            )}
            {brandDocumentationLink && brandDocumentationTitle && (
              <h3>
                <a target="_blank" rel="noopener noreferrer" href={brandDocumentationLink}>
                  {brandDocumentationTitle}
                </a>
              </h3>
            )}
          </div>
          {guideContent && (
            <div id="guide-content" className="container bg-white py-8 md:pt-20 lg:px-36">
              {parse(guideContent.html)}
            </div>
          )}
          {faqGroup && <FAQ content={faqGroup.faqgroup} />}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default GuideTemplate;

GuideTemplate.propTypes = {
  data: shape({
    graphCmsGuide: shape({
      heading: string.isRequired,
      headerImage: shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: object.isRequired,
      }),
      faqGroup: shape({
        faqgroup: arrayOf(
          shape({ question: string, deepLinkId: string, answer: shape({ html: string }) }),
        ),
      }),
      brandDocumentationLink: string,
      brandDocumentationTitle: string,
      youtubeEmbedLink: string,
      guideContent: shape({
        html: string,
      }),
      manufacturer: string.isRequired,
      connectedAccountCost: string,
      manufacturerWebsite: string,
      iosLink: string,
      androidLink: string,
      path: string.isRequired,
      seo: shape({
        title: string.isRequired,
        metaDescription: string.isRequired,
      }).isRequired,
      theme: string,
    }).isRequired,
  }).isRequired,
};

export const GuideTemplateQuery = graphql`
  query($slug: String!) {
    graphCmsGuide(path: { eq: $slug }) {
      heading
      faqGroup {
        faqgroup {
          ... on GraphCMS_Faq {
            question
            answer {
              html
            }
            deepLinkId
          }
        }
      }
      brandDocumentationLink
      brandDocumentationTitle
      youtubeEmbedLink
      manufacturer
      connectedAccountCost
      manufacturerWebsite
      iosLink
      androidLink
      path
      seo {
        metaDescription
        title
      }
      headerImage {
        gatsbyImageData
      }
      guideContent {
        html
      }
      theme
    }
  }
`;
